(function () {
    'use strict';

    angular
        .module('atheer.system')
        .controller('SkinSettingController', SkinSettingController);


    /* @ngInject */
    function SkinSettingController($rootScope, $scope, $state, $q, $cookies, $window, $http, triTheming, $mdDialog, Setting, Content, $filter, ToastService, Feature) {
        var vm = this;
        var triThemingPalettes = angular.copy(triTheming.palettes);
        vm.skinSettings = [];
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.switchModel = true;
        vm.toolBarBackground = '';
        vm.emailLayouts = [];
        vm.skinModel = {
            logo_small: null,
            logo_big: null,
            logo_small_dark: null,
            logo_big_dark: null,
            favorite_icon: null,
            use_dark_logos: false,
            primary_color: 'teal',
            primary_color_hex: '#009688',
            primary_color_rgba: 'rgb(0, 188, 212)',
            secondary_color: 'orange',
            secondary_color_hex: '#ffab40',
            secondary_color_rgba: 'rgb(255,171,64)',
            email_layout: 100,
            logo_right: 'false',
            side_menu_state: 'CLOSED',
            side_menu: 'LIGHT'
        };
        vm.elementPrimaryColors = {
            logo: '',
            sidebar: '',
            content: '',
            toolbar: ''
        };
        vm.elementSecondaryColors = {
            logo: '',
            sidebar: '',
            content: '',
            toolbar: ''
        };

        function loadData() {
            vm.loadingData = true;

            $q.all([
                Setting.getSkinSettings().$promise,
                Content.query({
                    query: 'type==EMAIL_LAYOUT'
                }).$promise
            ])
                .then(function (results) {
                    setSkinModel(results[0]);
                    vm.loadingData = false;
                    vm.emailLayouts = results[1];
                    updatePreview();
                });
        };

        function setSkinModel(skinSettings) {
            angular.forEach(skinSettings, function (setting) {
                if (setting.category == 'skin_info') {
                    vm.skinSettings = setting;
                    angular.forEach(setting.fields, function (settingField) {
                        if (settingField.value != null) {
                            if (settingField.name == 'logo_small') {
                                vm.skinModel.logo_small = settingField.value;
                            }
                            if (settingField.name == 'logo_big') {
                                vm.skinModel.logo_big = settingField.value;
                            }
                            if (settingField.name == 'logo_small_dark') {
                                vm.skinModel.logo_small_dark = settingField.value;
                            }
                            if (settingField.name == 'logo_big_dark') {
                                vm.skinModel.logo_big_dark = settingField.value;
                            }
                            if (settingField.name == 'favorite_icon') {
                                vm.skinModel.favorite_icon = settingField.value;
                            }
                            if (settingField.name == 'use_dark_logos') {
                                if (settingField.value == 'true') {
                                    vm.skinModel.use_dark_logos = true;
                                } else {
                                    vm.skinModel.use_dark_logos = false;
                                }
                            }
                            if (settingField.name == 'primary_color') {
                                vm.skinModel.primary_color = settingField.value;
                            }
                            if (settingField.name == 'primary_color_rgba') {
                                vm.skinModel.primary_color_rgba = settingField.value;
                            }
                            if (settingField.name == 'primary_color_hex') {
                                vm.skinModel.primary_color_hex = settingField.value;
                            }
                            if (settingField.name == 'secondary_color') {
                                vm.skinModel.secondary_color = settingField.value;
                            }
                            if (settingField.name == 'secondary_color_hex') {
                                vm.skinModel.secondary_color_hex = settingField.value;
                            }
                            if (settingField.name == 'secondary_color_rgba') {
                                vm.skinModel.secondary_color_rgba = settingField.value;
                            }
                            if (settingField.name == 'email_layout') {
                                vm.skinModel.email_layout = settingField.value;
                            }
                            if (settingField.name == 'side_menu') {
                                vm.skinModel.side_menu = settingField.value;
                            }
                            if (settingField.name == 'logo_right') {
                                vm.skinModel.logo_right = settingField.value;
                            }
                            if (settingField.name == 'side_menu_state') {
                                vm.skinModel.side_menu_state = settingField.value;
                            }
                        }
                    });
                }
            });
            updatePreview();
        }

        function setSkinSettings() {
            setSettingField("logo_small", vm.skinModel.logo_small);
            setSettingField("logo_big", vm.skinModel.logo_big);
            setSettingField("logo_small_dark", vm.skinModel.logo_small_dark);
            setSettingField("logo_big_dark", vm.skinModel.logo_big_dark);

            if (vm.skinModel.use_dark_logos) {
                setSettingField("use_dark_logos", 'true');
            } else {
                setSettingField("use_dark_logos", false);
            }
            setSettingField("favorite_icon", vm.skinModel.favorite_icon);
            setSettingField("primary_color", vm.skinModel.primary_color);
            setSettingField("primary_color_hex", vm.skinModel.primary_color_hex);
            setSettingField("primary_color_rgba", vm.skinModel.primary_color_rgba);
            setSettingField("secondary_color", vm.skinModel.secondary_color);
            setSettingField("secondary_color_hex", vm.skinModel.secondary_color_hex);
            setSettingField("secondary_color_rgba", vm.skinModel.secondary_color_rgba);
            setSettingField("email_layout", vm.skinModel.email_layout);
            setSettingField("side_menu", vm.skinModel.side_menu);
            setSettingField("logo_right", vm.skinModel.logo_right);
            setSettingField("side_menu_state", vm.skinModel.side_menu_state);
            // setSettingField("lense_homepage_json_file", vm.lensHomepageConfigFile);
        }

        function setSettingField(fieldName, fieldValue) {
            var foundField = false;
            angular.forEach(vm.skinSettings.fields, function (settingField) {
                if (settingField.name == fieldName) {
                    settingField.value = fieldValue;
                    foundField = true;
                }
            });

            if (!foundField) {
                var settingField = {
                    name: fieldName,
                    value: fieldValue,
                }
                vm.skinSettings.fields.push(settingField);
            }
        }

        function updateSwitch() {
            setTimeout(function () {
                var toggleSwitch = document.getElementById('ui-preview-switch');
                if (toggleSwitch) {
                    var toggleSwitchBar = toggleSwitch.children[0].children[0];
                    var barBackgroundColor = vm.skinModel.secondary_color_rgba;
                    toggleSwitchBar.style.backgroundColor = barBackgroundColor;

                    var toggleSwitchRound = toggleSwitch.children[0].children[1].children[0];
                    var roundBackgroundColor = vm.skinModel.secondary_color_rgba;
                    toggleSwitchRound.style.backgroundColor = roundBackgroundColor;
                }
            }, 300);
        }

        function updatePreview() {
            for (var element in vm.elementPrimaryColors) {
                var color = triTheming.getPaletteColor(vm.skinModel.primary_color, '500');
                vm.elementPrimaryColors[element] = vm.skinModel.primary_color_hex;
            }
            for (var element in vm.elementSecondaryColors) {
                var color = triTheming.getPaletteColor(vm.skinModel.secondary_color, '500');
                vm.elementSecondaryColors[element] = vm.skinModel.secondary_color_hex;
            }
            vm.toolBarBackground = vm.skinModel.primary_color_rgba;
            updateSwitch();
        }

        function saveSettings() {
            Setting.update(vm.skinSettings, function (result) {
                vm.skinSettings = result;
                ToastService.displayToastWithAction($filter('triTranslate')("atheer.setting.success"),
                    $filter('translate')('entity.action.refresh'), 'md-accent', '', function (response) {
                        if (response == 'ok') {
                            $window.location.reload();
                        }
                    });
            });
        }

        vm.trySkin = function () {
            if (vm.selectedSkin !== triSkins.getCurrent()) {
                $cookies.put('storm-skin', angular.toJson({
                    skin: vm.selectedSkin.id
                }));
                $window.location.reload();
            }
        }

        vm.save = function () {
            setSkinSettings();
            updatePreview();
            saveSettings();
        }

        $scope.$watch('vm.skinModel.primary_color_hex', function (newVal) {
            var color = tinycolor(newVal);
            var colorName = null;
            var colorHexString = color.toHexString();

            angular.forEach(triThemingPalettes, function (item, key) {
                if (item['500'].hex == colorHexString) {
                    colorName = key;
                }
            });

            if (colorName == null) {
                colorName = 'custom-primary-color';
            };

            vm.skinModel.primary_color = colorName;
            vm.skinModel.primary_color_rgba = color.toRgbString();
            updatePreview();
        });

        $scope.$watch('vm.skinModel.secondary_color_hex', function (newVal) {
            var color = tinycolor(newVal);
            var colorName = null;
            var colorHexString = color.toHexString();

            angular.forEach(triThemingPalettes, function (item, key) {
                if (item['500'].hex == colorHexString) {
                    colorName = key;
                }
            });

            if (colorName == null) {
                colorName = 'custom-secondary-color';
            };

            vm.skinModel.secondary_color = colorName;
            vm.skinModel.secondary_color_rgba = color.toRgbString();
            updatePreview();
        });

        $scope.$watch('vm.skinModel.side_menu', function (newVal) {
            updatePreview();
        });

        loadData();
    }
})();
