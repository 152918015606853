(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('LinkDetailController', LinkDetailController)
        .controller('LinkViewController', LinkViewController)
        .controller('LinkFeedbackController', LinkFeedbackController)

    /* @ngInject */
    function LinkFeedbackController($scope) {

    }

    /* @ngInject */
    function LinkViewController($scope, $controller, $mdBottomSheet) {

        var basectrl = $controller('BaseContentViewController as vm', {
            $scope: $scope
        });
        angular.extend(this, basectrl);

        $scope.showFeedback = function(firstTime) {
            $mdBottomSheet.show({
                templateUrl: 'modules/content/link-feedback.tmpl.html',
                clickOutsideToClose: false,
                escapeToClose: false,
                controller: 'LinkFeedbackController',
                scope: $scope,
                preserveScope: true,
                parent: '#fp-content-view-' + $scope.data.id + '-' + $scope.context,
                disableParentScroll: true,
                locals: {
                    firstTime: firstTime
                }
            });
        }

        $scope.getSpacerHeight = function(id) {
            var element = angular.element(document.querySelector('#fp-content-description-' + id));
            if (element && element[0]) {
                var height = element[0].offsetHeight + ($scope.data.data.thumbnail_url ? 100 : 0);
                if (height > 100) {
                    return 0;
                } else {
                    return 100 - height;
                }
                return height;
            } else {
                return 100;
            }
        }
    }

    /* @ngInject */
    function LinkDetailController($scope, $timeout, $controller, id, Content) {

        var basectrl = $controller('BaseContentDetailController as vm', {
            $scope: $scope,
            id: id,
            content_type: "link"
        });
        angular.extend(this, basectrl);

        var vm = this;

        vm.extractStatus = 'idle';

        vm.extractLinkDetails = function() {
            vm.extractStatus = 'extracting';

            $timeout(function() {
                vm.extractLinkData();
            }, 1000);
        }

        vm.extractLinkData = function() {
            vm.promise = Content.extract({
                url: vm.contentModel.data.source_url
            }).$promise;

            vm.promise.then(function(result, headers) {
                if (angular.isDefined(result.title) && result.title != null && result.title.length > 0) {
                    vm.contentMetaData.show_tags = true;

                    vm.contentModel.title = result.title;
                    vm.contentModel.description = result.description;
                    vm.contentModel.external_id = result.external_id;
                    if (result.authors && result.authors.length > 0) {
                        vm.contentModel.data.author = result.authors[0].name;
                    }

                    if (result.images && result.images.length > 0) {
                        vm.contentModel.data.thumbnail_url = result.images[0].url;
                    }

                    vm.contentModel.data.use_custom_thumbnail = true;
                    vm.contentModel.data.provider = result.provider;
                    vm.contentModel.data.link_type = result.type;
                    vm.contentModel.tags = result.tags;
                    vm.contentModel.data.extract_id = result.id;
                    vm.extractStatus = 'complete';

                    $timeout(function() {
                        vm.extractStatus = 'ready';
                        vm.save();
                    }, 2000);

                } else {
                    vm.extractStatus = 'incomplete';

                    $timeout(function() {
                        vm.extractStatus = 'ready';
                    }, 2000);
                }
            }, function(error) {
                vm.extractStatus = 'error';

                $timeout(function() {
                    vm.extractStatus = 'ready';
                }, 2000);
            });
        }
    }
})();
