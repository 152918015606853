(function () {
    'use strict';
    angular
        .module('atheer')
        .directive('fpHeader', fpHeader);

    function fpHeader() {
        return {
            restrict: 'E',
            scope: {
                isPublicUser: '=',
                pageName: '=',
                userInfo: '=',
                title: '='
            },
            templateUrl: 'core/components/header/header.tmpl.html',
            controller: headerController,
            controllerAs: 'vm'
        }
    }

    /* @ngInject */
    function headerController($scope, $state, $window, Auth, deviceDetector, triSettings) {
        var vm = this;
        vm.isPublicUser = $scope.isPublicUser;
        vm.pageName = $scope.pageName;
        vm.isMobileBrowser = !deviceDetector.isDesktop();
        vm.triSettings = triSettings;
        vm.userInfo = $scope.userInfo;
        $scope.bgColor = vm.isPublicUser ? triSettings.getSkinModel().primary_color_hex : (vm.pageName !== 'Home' ? '#ffffff' : triSettings.getSkinModel().primary_color_hex);

        vm.logout = function () {
            Auth.logout();
            $state.go('account.signout');
        };

        vm.navigateHome = function () {
            $state.go('storm.home');
        };
    }

})();