(function () {
    'use strict';

    angular
        .module('atheer.user')
        .controller('ProfileAboutController', ProfileAboutController);

    /* @ngInject */
    function ProfileAboutController($q, MetaData, Principal, User, $filter, id, System, LocalResourceService, $window, translationStorageProvider, ToastService) {
        var vm = this;

        var FORMLY_ATHEER_DATA_TYPE_MAPPING = {
            'BOOLEAN': 'checkbox',
            'STRING': 'input',
            'LIST': 'select',
            'DATE': 'datepicker',
            'DATETIME': 'datepicker',
            'NUMBER': 'input',
            'DECIMAL': 'input',
            'OBJECT': 'objectpicker'
        };

        var FORMLY_TEMPLATE_TYPE_ATHEER_DATA_TYPE_MAPPING = {
            'STRING': 'text',
            'NUMBER': 'number',
            'DECIMAL': 'number'
        };

        vm.timezones = [];
        vm.languages = [];
        vm.user = null;
        vm.save = save;
        vm.disableEdit = false;
        vm.isAdminView = id != 'me';
        vm.profile = {};
        vm.profileMeta = null;
        vm.profileMetaFields = [];
        vm.profileViewMode = id == 'me' ? 'me' : 'admin';
        vm.isAdminMode = vm.profileViewMode == 'admin';
        vm.searchText = null;
        vm.userInfo = Principal.getUserInfo();
        vm.isPublicUser = false;

        vm.userId = id == 'me' ? Principal.getUserId() : id;

        vm.loadData = function () {
            vm.loadingData = true;

            $q.all([
                User.getById({
                    id: vm.userId
                }).$promise,
                System.getTimezones({
                    size: 100
                }).$promise,
                System.getLanguages({
                    localisation_support: true
                }).$promise,
                MetaData.get({
                    id: 'user',
                    is_name: true
                }).$promise,
                LocalResourceService.get({ fileName: 'languages.json' }).$promise
            ]).then(function (data) {
                vm.user = data[0];
                vm.title = $filter('translate')('atheer.userDetail.menuTitle', { userFirstName: vm.user.first_name })
                vm.timezones = data[1];
                // vm.languages = data[2];
                if (vm.user.login == 'admin') {
                    vm.disableEdit = true;
                }

                angular.forEach(data[3].field_groups, function (field_group) {
                    if (field_group.name == 'profile') {
                        vm.profileMeta = field_group;
                    }
                });

                angular.forEach(vm.profileMeta.fields, function (field) {
                    if (field.type == 'FLEX') {
                        vm.profileMetaFields.push(getFormlyField(field));
                    }
                });
                vm.languages = data[4].supportedLanguages;
                vm.languageCodes = data[4].languageCodes;
                vm.loadingData = false;

            }).catch(function (data) { }).finally(function () { });
        };

        vm.getLabel = function (item) {
            return item.first_name + " " + item.last_name;
        }

        vm.userSearchQuery = function (searchText) {
            return User.query({
                page: 0,
                size: 10,
                sort: 'id,asc',
                query: getQueryString(searchText),
                check_admin: true
            }).$promise
        };

        function getQueryString(searchText) {
            var searchQuery = "(";

            searchQuery += 'first_name=re="' + searchText + '"';
            searchQuery += ',last_name=re="' + searchText + '"';
            searchQuery += ',email=re="' + searchText + '"';

            searchQuery += ")";

            return searchQuery
        };

        function save() {
            var userCopy = JSON.parse(JSON.stringify(vm.user));
            var dateMinusTimeFormat = "yyyy-MM-ddT00:00:00.000Z";
            for (var prop in vm.user) {
                var userAttr = vm.user[prop];
                if (userAttr instanceof Date && !isNaN(userAttr)) {
                    userCopy[prop] = $filter('date')(userAttr, dateMinusTimeFormat);
                }
            }
            if (vm.user.custom_attributes) {
                for (var cust_prop in vm.user.custom_attributes) {
                    if (vm.user.custom_attributes[cust_prop] instanceof Date && !isNaN(vm.user.custom_attributes[cust_prop])) {
                        userCopy.custom_attributes[cust_prop] = $filter('date')(vm.user.custom_attributes[cust_prop], dateMinusTimeFormat);
                    }
                }
            }

            if (vm.isAdminView) {
                User.update(vm.user, function (result) {
                    postSave(result);
                });
            }
            else {
                User.updateme(vm.user, function (result) {
                    postSave(result);
                });
            }
        };

        function postSave(result) {
            vm.profileMetaFields = []
            vm.user = result;
            angular.forEach(vm.profileMeta.fields, function (field) {
                if (field.type == 'FLEX') {
                    vm.profileMetaFields.push(getFormlyField(field));
                }
            });
            if (result.id == Principal.getUserId()) {
                angular.forEach(vm.languageCodes, function (lang) {
                    if (lang.name == result.language_id) {
                        if (lang.name != 'system_default') {
                            translationStorageProvider.put('NG_TRANSLATE_LANG_KEY', lang.value);
                        } else {
                            var lang = ($window.navigator.language || $window.navigator.userLanguage).replace('-', '_');
                            if (lang == 'el') {
                                lang = 'el_GR';
                            } else if (lang == 'ja') {
                                lang = 'ja_JP';
                            }
                            translationStorageProvider.put('NG_TRANSLATE_LANG_KEY', lang);
                        }
                        localStorage.setItem('isUserSelectedLanguage', true)
                    }
                });
                ToastService.displayToastWithAction($filter('triTranslate')("atheer.about.changesUpdated"),
                    $filter('translate')('entity.action.refresh'), 'md-warn', '', function (res) {
                        if (res == 'ok') {
                            $window.location.reload();
                        }
                    });
            } else {
                ToastService.displayToast($filter('triTranslate')("atheer.about.changesUpdated"), 3000);
            }
        }

        function getFormlyField(atheerfield) {
            var formlytype = FORMLY_ATHEER_DATA_TYPE_MAPPING[atheerfield.data_type];
            var formlyTemplateType = FORMLY_TEMPLATE_TYPE_ATHEER_DATA_TYPE_MAPPING[atheerfield.data_type];
            var formlyField = {
                type: formlytype ? formlytype : 'input',
                key: atheerfield.name,
                templateOptions: {
                    label: atheerfield.label,
                    required: atheerfield.required
                }
            };
            if (formlytype == "datepicker") {
                formlyField.templateOptions['placeholder'] = $filter('translate')('atheer.about.selectDate');
            }
            if (formlyTemplateType) {
                formlyField.templateOptions['type'] = formlyTemplateType;
            }
            if (atheerfield.data_type == 'DATETIME' || atheerfield.data_type == 'DATE') {
                if (vm.user.custom_attributes[atheerfield.name]) {
                    vm.user.custom_attributes[atheerfield.name] = new Date(vm.user.custom_attributes[atheerfield.name]);
                }
            }

            if (atheerfield.data_type == 'DATETIME') {
                formlyField.templateOptions['datepickerPopup'] = "HH:MM";
            }

            if (atheerfield.data_type == 'LIST') {
                formlyField.templateOptions['labelProp'] = "label";
                formlyField.templateOptions['valueProp'] = "option_id";
                formlyField.templateOptions['options'] = atheerfield.list_options;
            }

            if (atheerfield.data_type == 'OBJECT') {
                if (atheerfield.ref_object == 'Content') {
                    formlyField.templateOptions['objectName'] = 'Content';
                    formlyField.templateOptions['queryObject'] = [{
                        fieldName: 'title',
                        operator: '=re='
                    }];
                } else if (atheerfield.ref_object == 'User') {
                    formlyField.templateOptions['objectName'] = 'User';
                    formlyField.templateOptions['queryObject'] = [{
                        fieldName: 'text_search',
                        operator: '=ts='
                    }];
                }
            }
            return formlyField;
        };

        vm.loadData();
    }
})();
