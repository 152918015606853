(function() {
    'use strict';
    angular
        .module('atheer.services')
        .factory('Session', Session);


    /* @ngInject */
    function Session($resource) {
        var resourceUrl = 'api/sessions/:id';

        return $resource(resourceUrl, {
            id: '@id'
        }, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getTemplate': {
                  url: 'api/sessions/:id?type=TEMPLATE',
                  method: 'GET',
            },
            'getSessionSummaryReport': {
                  url: 'api/sessions/summary/report',
                  method: 'GET',
            },
            'update': {
                method: 'PUT'
            },
            'updateScheduled': {
                url: 'api/sessions/:id?send_invite=true',
                method: 'PUT'
            },
            'invite': {
                url: 'api/sessions/:id/invite',
                method: 'POST'
            },
            'getPersonalRoomSummary': {
                url: 'api/sessions/personal/summary',
                method: 'GET'
            },
            'getPersonalRoomInstances': {
                url: 'api/sessions/personal/instances',
                method: 'GET',
                isArray: true
            },
            'getAnnotationZip': {
                url: 'api/sessions/annotationImages/:id',
                method: 'GET'
            },
            'getImportStatus': {
                  url: 'api/system/job-streams/:id',
                  method: 'GET'
              },
        });
    }
})();
