(function() {

    'use strict';
    angular
        .module('atheer.content')
        .constant('CONTENT_META', [{
            type: 'announcement',
            meta_type: 'ANNOUNCEMENT',
            label: 'atheer.content.menu.announcement',
            feature_name: 'feature_content_announcement',
            dispay_in_options: true,
            display_order: 7,
            category: 'main',
            show_copy_action: false,
            show_assign_action: true,
            show_ask_action: false,
            show_recommend_action: false,
            show_in_smart_content: false,
            icon: 'fa fa-bullhorn',
            show_audiences: false,
            show_scheduling: false,
            show_folders: false,
            show_topics: false,
            show_tags: false,
            enable_audiences: true,
            enable_scheduling: true,
            enable_folders: true,
            enable_topics: false,
            enable_tags: true,
            enable_audio: false,
            enable_media: true,
            enable_media_layout: false,
            media_label: 'atheer.content.thumbnailImage',
            title_label: 'atheer.content.title',
            externalId_label: 'atheer.content.externalId',
            enable_thumbnail: false,
            enable_duration: false,
            enable_level: false,
            enable_featured: false,
            enable_required: false,
            enable_title_alignment: false,
            enable_title: false,
            enable_description: false,
            description_label: 'atheer.content.announcementText',
            enable_request_rating: false,
            enable_points: false,
            enable_feedback: false,
            enable_insights: true,
            default_model: {
                title: 'atheer.content.title',
                description: null,
                show_title: true,
                show_description: false,
                title_alignment: 'CENTER',
                show_media: false,
                media_shape: 'SQUARE',
                media_size: 'MEDIUM',
                media_position: 'BELOW_TITLE',
                media_alignment: 'CENTER',
                show_audio: false,
                audio_id: null,
                media_id: null,
                status: 'DRAFT',
                enabled: true,
                points: 0,
                embedded: false,
                tags: [],
                data: {
                    type: 'announcement',
                    show_title: true,
                    title_color: '#343A3A',
                    show_content: true,
                    content_color: '#343A3A',
                    content: null,
                    available_from: new Date(),
                    discontinue_from: null
                }
            }
        }, {
            type: 'document',
            meta_type: 'DISCOVERABLE',
            label: 'atheer.content.menu.document',
            feature_name: 'feature_content_document',
            dispay_in_options: true,
            display_order: 5,
            category: 'main',
            show_copy_action: false,
            show_assign_action: true,
            show_ask_action: false,
            show_recommend_action: true,
            show_in_smart_content: true,
            icon: 'zmdi zmdi-file-text',
            show_audiences: false,
            show_scheduling: false,
            show_folders: false,
            show_topics: false,
            show_tags: false,
            enable_audiences: true,
            enable_scheduling: true,
            enable_folders: true,
            enable_topics: true,
            enable_tags: true,
            enable_audio: false,
            enable_media: false,
            enable_media_layout: false,
            media_label: 'atheer.content.menu.image',
            title_label: 'atheer.content.title',
            externalId_label: 'atheer.content.externalId',
            enable_thumbnail: true,
            enable_duration: true,
            enable_level: false,
            enable_featured: false,
            enable_required: false,
            enable_title_alignment: false,
            enable_title: false,
            enable_description: true,
            description_label: 'atheer.content.description',
            enable_request_rating: false,
            enable_points: false,
            enable_feedback: true,
            enable_insights: true,
            card_properties: {
                actions: [],
                smallIcon: 'assets/images/card/document.png',
                defaultIcon: 'assets/images/card/document_tn.png',
                launchIcon: 'assets/images/card/play_tn.png',
                defaultBackground: '#bdbec0',
                template: 'content-card.tmpl.html'
            },
            default_model: {
                title: 'Untitled',
                description: null,
                show_title: true,
                show_description: true,
                title_alignment: 'CENTER',
                show_media: false,
                media_shape: 'SQUARE',
                media_size: 'MEDIUM',
                media_position: 'BELOW_TITLE',
                media_alignment: 'CENTER',
                status: 'DRAFT',
                enabled: true,
                points: 0,
                embedded: false,
                tags: [],
                data: {
                    type: 'document',
                    featured: false,
                    duration: 5,
                    level: 'ALL',
                    use_custom_thumbnail: false,
                    thumbnail_url: null,
                    available_from: new Date(),
                    discontinue_from: null,
                    source_media: null,
                    doc_type: null,
                    doc_status: null,
                    request_rating: false,
                    properties: null
                }
            }
        }, {
            type: 'image',
            meta_type: 'DISCOVERABLE',
            label: 'atheer.content.menu.image',
            feature_name: 'feature_content_document',
            dispay_in_options: true,
            display_order: 5,
            category: 'main',
            show_copy_action: false,
            show_assign_action: true,
            show_ask_action: false,
            show_recommend_action: true,
            show_in_smart_content: true,
            icon: 'zmdi zmdi-image',
            show_audiences: false,
            show_scheduling: false,
            show_folders: false,
            show_topics: false,
            show_tags: false,
            enable_audiences: true,
            enable_scheduling: true,
            enable_folders: true,
            enable_topics: true,
            enable_tags: true,
            enable_audio: false,
            enable_media: false,
            enable_media_layout: false,
            media_label: 'atheer.content.menu.image',
            title_label: 'atheer.content.title',
            externalId_label: 'atheer.content.externalId',
            enable_thumbnail: true,
            enable_duration: true,
            enable_level: false,
            enable_featured: false,
            enable_required: false,
            enable_title_alignment: false,
            enable_title: false,
            enable_description: true,
            description_label: 'atheer.content.description',
            enable_request_rating: false,
            enable_points: false,
            enable_feedback: true,
            enable_insights: true,
            card_properties: {
                actions: [],
                smallIcon: 'assets/images/card/document.png',
                defaultIcon: 'assets/images/card/document_tn.png',
                launchIcon: 'assets/images/card/play_tn.png',
                defaultBackground: '#bdbec0',
                template: 'content-card.tmpl.html'
            },
            default_model: {
                title: 'Untitled',
                description: null,
                show_title: true,
                show_description: true,
                title_alignment: 'CENTER',
                show_media: false,
                media_shape: 'SQUARE',
                media_size: 'MEDIUM',
                media_position: 'BELOW_TITLE',
                media_alignment: 'CENTER',
                status: 'DRAFT',
                enabled: true,
                points: 0,
                embedded: false,
                tags: [],
                data: {
                    type: 'image',
                    featured: false,
                    duration: 5,
                    level: 'ALL',
                    use_custom_thumbnail: false,
                    thumbnail_url: null,
                    available_from: new Date(),
                    discontinue_from: null,
                    source_media: null,
                    image_type: null,
                    image_status: null,
                    request_rating: false,
                    properties: null
                }
            }
        }, {
            type: 'session-recording',
            meta_type: 'DISCOVERABLE',
            label: 'atheer.content.menu.airSessionRecording',
            feature_name: 'feature_content_airsession_recording',
            dispay_in_options: true,
            display_order: 5,
            category: 'main',
            show_copy_action: false,
            show_assign_action: true,
            show_ask_action: false,
            show_recommend_action: true,
            show_in_smart_content: true,
            icon: 'zmdi zmdi-comment-video',
            show_audiences: false,
            show_scheduling: false,
            show_folders: false,
            show_topics: false,
            show_tags: false,
            enable_audiences: true,
            enable_scheduling: true,
            enable_folders: true,
            enable_topics: true,
            enable_tags: true,
            enable_audio: false,
            enable_media: false,
            enable_media_layout: false,
            media_label: 'atheer.content.menu.image',
            title_label: 'atheer.content.title',
            externalId_label: 'atheer.content.externalId',
            enable_thumbnail: true,
            enable_duration: true,
            enable_level: false,
            enable_featured: false,
            enable_required: false,
            enable_title_alignment: false,
            enable_title: false,
            enable_description: true,
            description_label: 'atheer.content.description',
            enable_request_rating: false,
            enable_points: false,
            enable_feedback: true,
            enable_insights: false,
            card_properties: {
                actions: [],
                smallIcon: 'assets/images/card/document.png',
                defaultIcon: 'assets/images/card/document_tn.png',
                launchIcon: 'assets/images/card/play_tn.png',
                defaultBackground: '#bdbec0',
                template: 'content-card.tmpl.html'
            },
            default_model: {
                title: 'Untitled',
                description: null,
                show_title: true,
                show_description: true,
                title_alignment: 'CENTER',
                show_media: false,
                media_shape: 'SQUARE',
                media_size: 'MEDIUM',
                media_position: 'BELOW_TITLE',
                media_alignment: 'CENTER',
                status: 'DRAFT',
                enabled: true,
                points: 0,
                embedded: false,
                tags: [],
                data: {
                    type: 'session_recording',
                    featured: false,
                    duration: 5,
                    level: 'ALL',
                    use_custom_thumbnail: false,
                    thumbnail_url: null,
                    available_from: new Date(),
                    discontinue_from: null,
                    source_media: null,
                    image_type: null,
                    image_status: null,
                    request_rating: false,
                    properties: null
                }
            }
        }, {
            type: 'did-you-know',
            meta_type: 'DID_YOU_KNOW',
            label: 'atheer.content.menu.didYouKnow',
            feature_name: 'feature_content_did_you_know',
            dispay_in_options: true,
            display_order: 8,
            category: 'main',
            show_copy_action: false,
            show_assign_action: false,
            show_ask_action: false,
            show_recommend_action: false,
            show_in_smart_content: false,
            icon: 'fa fa-lightbulb-o',
            show_audiences: false,
            show_scheduling: false,
            show_folders: false,
            show_topics: false,
            show_tags: false,
            enable_audiences: false,
            enable_scheduling: true,
            enable_folders: true,
            enable_topics: false,
            enable_tags: true,
            enable_audio: false,
            enable_media: true,
            enable_media_layout: false,
            media_label: 'atheer.content.thumbnailImage',
            title_label: 'atheer.content.title',
            externalId_label: 'atheer.content.externalId',
            enable_thumbnail: false,
            enable_duration: false,
            enable_level: false,
            enable_featured: false,
            enable_required: false,
            enable_title_alignment: false,
            enable_title: false,
            enable_description: false,
            description_label: 'atheer.content.description',
            enable_request_rating: false,
            enable_points: false,
            enable_feedback: false,
            enable_insights: false,
            default_model: {
                title: 'atheer.content.title',
                description: null,
                show_title: true,
                show_description: true,
                title_alignment: 'CENTER',
                show_media: false,
                media_shape: 'SQUARE',
                media_size: 'MEDIUM',
                media_position: 'BELOW_TITLE',
                media_alignment: 'CENTER',
                show_audio: false,
                audio_id: null,
                media_id: null,
                status: 'DRAFT',
                enabled: true,
                points: 0,
                embedded: false,
                tags: [],
                data: {
                    type: 'did_you_know',
                    show_title: true,
                    title_color: '#343A3A',
                    show_content: true,
                    content_color: '#343A3A',
                    content: null,
                    available_from: new Date(),
                    discontinue_from: null
                }
            }
        }, {
            type: 'smart-page',
            meta_type: 'DISCOVERABLE',
            label: 'atheer.content.menu.article',
            feature_name: 'feature_content_page',
            dispay_in_options: true,
            display_order: 5,
            category: 'main',
            show_copy_action: false,
            show_assign_action: true,
            show_ask_action: false,
            show_recommend_action: true,
            show_in_smart_content: false,
            icon: 'zmdi zmdi-file',
            show_audiences: false,
            show_scheduling: false,
            show_folders: false,
            show_topics: false,
            show_tags: false,
            enable_audiences: true,
            enable_scheduling: true,
            enable_folders: true,
            enable_topics: true,
            enable_tags: true,
            enable_audio: false,
            enable_media: false,
            enable_media_layout: false,
            media_label: 'atheer.content.menu.image',
            title_label: 'atheer.content.title',
            externalId_label: 'atheer.content.externalId',
            enable_thumbnail: true,
            enable_duration: true,
            enable_level: false,
            enable_featured: false,
            enable_required: false,
            enable_title_alignment: false,
            enable_title: false,
            enable_description: false,
            description_label: 'atheer.content.description',
            enable_request_rating: false,
            enable_points: false,
            enable_feedback: true,
            enable_insights: true,
            card_properties: {
                actions: [],
                smallIcon: 'assets/images/card/smart_page.png',
                defaultIcon: 'assets/images/card/smart_page_tn.png',
                launchIcon: 'assets/images/card/play_tn.png',
                defaultBackground: '#A97C50',
                template: 'content-card.tmpl.html'
            },
            default_model: {
                title: 'Untitled',
                description: null,
                show_title: true,
                show_description: true,
                title_alignment: 'CENTER',
                show_media: false,
                media_shape: 'SQUARE',
                media_size: 'MEDIUM',
                media_position: 'BELOW_TITLE',
                media_alignment: 'CENTER',
                show_audio: false,
                audio_id: null,
                media_id: null,
                status: 'DRAFT',
                enabled: true,
                points: 0,
                embedded: false,
                tags: [],
                data: {
                    type: 'smart_page',
                    featured: false,
                    duration: 5,
                    level: 'ALL',
                    use_custom_thumbnail: false,
                    thumbnail_url: null,
                    content: null,
                    available_from: new Date(),
                    discontinue_from: null,
                    request_rating: false,
                    properties: null
                }
            }
        }, {
            type: 'podcast',
            meta_type: 'DISCOVERABLE',
            label: 'atheer.content.menu.podcast',
            feature_name: 'feature_content_podcast',
            dispay_in_options: true,
            display_order: 4,
            category: 'main',
            show_copy_action: false,
            show_assign_action: true,
            show_ask_action: false,
            show_recommend_action: true,
            show_in_smart_content: true,
            icon: 'zmdi zmdi-headset-mic',
            show_audiences: false,
            show_scheduling: false,
            show_folders: false,
            show_topics: false,
            show_tags: false,
            enable_audiences: true,
            enable_scheduling: true,
            enable_folders: true,
            enable_topics: true,
            enable_tags: true,
            enable_audio: false,
            enable_media: false,
            enable_media_layout: false,
            media_label: 'atheer.content.menu.image',
            title_label: 'atheer.content.title',
            externalId_label: 'atheer.content.externalId',
            enable_thumbnail: true,
            enable_duration: true,
            enable_level: false,
            enable_featured: false,
            enable_required: false,
            enable_title_alignment: false,
            enable_title: false,
            enable_description: true,
            description_label: 'atheer.content.description',
            enable_request_rating: false,
            enable_points: false,
            enable_feedback: true,
            enable_insights: true,
            card_properties: {
                actions: [],
                smallIcon: 'assets/images/card/excourse.png',
                defaultIcon: 'assets/images/card/listen_tn.png',
                launchIcon: 'assets/images/card/play_tn.png',
                defaultBackground: '#806193',
                template: 'content-card.tmpl.html'
            },
            default_model: {
                title: 'Untitled',
                description: null,
                show_title: true,
                show_description: true,
                title_alignment: 'CENTER',
                show_media: false,
                media_shape: 'SQUARE',
                media_size: 'MEDIUM',
                media_position: 'BELOW_TITLE',
                media_alignment: 'CENTER',
                show_audio: false,
                audio_id: null,
                media_id: null,
                status: 'DRAFT',
                enabled: true,
                points: 0,
                embedded: false,
                tags: [],
                data: {
                    type: 'podcast',
                    featured: false,
                    duration: 5,
                    level: 'ALL',
                    use_custom_thumbnail: false,
                    thumbnail_url: null,
                    available_from: new Date(),
                    discontinue_from: null,
                    request_rating: false,
                    properties: null
                }
            }
        }, {
            type: 'video',
            meta_type: 'DISCOVERABLE',
            label: 'atheer.content.menu.video',
            feature_name: 'feature_content_video',
            dispay_in_options: true,
            display_order: 3,
            category: 'main',
            show_copy_action: false,
            show_assign_action: true,
            show_ask_action: false,
            show_recommend_action: true,
            show_in_smart_content: true,
            icon: 'zmdi zmdi-collection-video',
            show_audiences: false,
            show_scheduling: false,
            show_folders: false,
            show_topics: false,
            show_tags: false,
            enable_audiences: true,
            enable_scheduling: true,
            enable_folders: true,
            enable_topics: true,
            enable_tags: true,
            enable_audio: false,
            enable_media: false,
            enable_media_layout: false,
            media_label: 'atheer.content.menu.image',
            title_label: 'atheer.content.title',
            externalId_label: 'atheer.content.externalId',
            enable_thumbnail: true,
            enable_duration: true,
            enable_level: false,
            enable_featured: false,
            enable_required: false,
            enable_title_alignment: false,
            enable_title: false,
            enable_description: true,
            description_label: 'atheer.content.description',
            enable_request_rating: false,
            enable_points: false,
            enable_feedback: true,
            enable_insights: true,
            card_properties: {
                actions: [],
                smallIcon: 'assets/images/card/video.png',
                defaultIcon: 'assets/images/card/play_tn.png',
                launchIcon: 'assets/images/card/play_tn.png',
                defaultBackground: '#faaf40',
                template: 'content-card.tmpl.html'
            },
            default_model: {
                title: 'Untitled',
                description: null,
                show_title: true,
                show_description: true,
                title_alignment: 'CENTER',
                show_media: false,
                media_shape: 'SQUARE',
                media_size: 'MEDIUM',
                media_position: 'BELOW_TITLE',
                media_alignment: 'CENTER',
                show_audio: false,
                audio_id: null,
                media_id: null,
                status: 'DRAFT',
                enabled: true,
                points: 0,
                embedded: false,
                tags: [],
                data: {
                    type: 'video',
                    featured: false,
                    duration: 5,
                    level: 'ALL',
                    use_custom_thumbnail: false,
                    thumbnail_url: null,
                    available_from: new Date(),
                    discontinue_from: null,
                    request_rating: false,
                    external_video: false,
                    source_url: null,
                    embed_code: null,
                    extract_id: null,
                    oembed_id: null,
                    provider: null,
                    progress: 0,
                    show_audio: false,
                    audio_id: null,
                    media_id: null,
                    video_status: 'UPLOADED',
                    properties: null
                }
            }
        }, {
            type: 'zip',
            meta_type: 'DISCOVERABLE',
            label: 'atheer.content.menu.zip',
            feature_name: 'feature_content_zip',
            dispay_in_options: true,
            display_order: 5,
            category: 'main',
            show_copy_action: false,
            show_assign_action: true,
            show_ask_action: false,
            show_recommend_action: true,
            show_in_smart_content: true,
            icon: 'assets/images/card/zipfolder.svg',
            show_audiences: false,
            show_scheduling: false,
            show_folders: false,
            show_topics: false,
            show_tags: false,
            enable_audiences: true,
            enable_scheduling: true,
            enable_folders: true,
            enable_topics: true,
            enable_tags: true,
            enable_audio: false,
            enable_media: false,
            enable_media_layout: false,
            media_label: 'atheer.content.menu.zip',
            title_label: 'atheer.content.title',
            externalId_label: 'atheer.content.externalId',
            enable_thumbnail: true,
            enable_duration: true,
            enable_level: false,
            enable_featured: false,
            enable_required: false,
            enable_title_alignment: false,
            enable_title: false,
            enable_description: true,
            description_label: 'atheer.content.description',
            enable_request_rating: false,
            enable_points: false,
            enable_feedback: true,
            enable_insights: true,
            card_properties: {
                actions: [],
                smallIcon: 'assets/images/card/zipfolder.svg',
                defaultIcon: 'assets/images/card/zipfolder.svg',
                launchIcon: 'assets/images/card/zipfolder.svg',
                defaultBackground: '#bdbec0',
                template: 'content-card.tmpl.html'
            },
            default_model: {
                title: 'Untitled',
                description: null,
                show_title: true,
                show_description: true,
                title_alignment: 'CENTER',
                show_media: false,
                media_shape: 'SQUARE',
                media_size: 'MEDIUM',
                media_position: 'BELOW_TITLE',
                media_alignment: 'CENTER',
                status: 'DRAFT',
                enabled: true,
                points: 0,
                embedded: false,
                tags: [],
                data: {
                    type: 'zip',
                    featured: false,
                    duration: 5,
                    level: 'ALL',
                    use_custom_thumbnail: false,
                    thumbnail_url: null,
                    available_from: new Date(),
                    discontinue_from: null,
                    source_media: null,
                    doc_type: null,
                    doc_status: null,
                    request_rating: false,
                    properties: null
                }
            }
        },, {
            type: 'link',
            meta_type: 'DISCOVERABLE',
            label: 'atheer.content.menu.post',
            feature_name: 'feature_content_link',
            dispay_in_options: true,
            display_order: 6,
            category: 'main',
            show_copy_action: false,
            show_assign_action: true,
            show_ask_action: false,
            show_recommend_action: true,
            show_in_smart_content: true,
            icon: 'zmdi zmdi-link',
            show_audiences: false,
            show_scheduling: false,
            show_folders: false,
            show_topics: false,
            show_tags: false,
            enable_audiences: true,
            enable_scheduling: true,
            enable_folders: true,
            enable_topics: true,
            enable_tags: true,
            enable_audio: false,
            enable_media: false,
            enable_media_layout: false,
            media_label: 'atheer.content.menu.image',
            title_label: 'atheer.content.title',
            externalId_label: 'atheer.content.externalId',
            enable_thumbnail: true,
            enable_duration: true,
            enable_level: false,
            enable_featured: false,
            enable_required: false,
            enable_title_alignment: false,
            enable_title: false,
            enable_description: true,
            description_label: 'atheer.content.description',
            enable_request_rating: false,
            enable_points: false,
            enable_feedback: true,
            enable_insights: true,
            card_properties: {
                actions: [],
                smallIcon: 'assets/images/card/link.png',
                defaultIcon: 'assets/images/card/link_tn.png',
                launchIcon: 'assets/images/card/play_tn.png',
                defaultBackground: '#2880ba',
                template: 'content-card.tmpl.html'
            },
            default_model: {
                title: 'Untitled',
                description: null,
                show_title: true,
                show_description: true,
                title_alignment: 'CENTER',
                show_media: false,
                media_shape: 'SQUARE',
                media_size: 'MEDIUM',
                media_position: 'BELOW_TITLE',
                media_alignment: 'CENTER',
                show_audio: false,
                audio_id: null,
                media_id: null,
                status: 'DRAFT',
                enabled: true,
                points: 0,
                embedded: false,
                tags: [],
                data: {
                    type: 'link',
                    featured: false,
                    duration: 5,
                    level: 'ALL',
                    use_custom_thumbnail: false,
                    thumbnail_url: null,
                    available_from: new Date(),
                    discontinue_from: null,
                    request_rating: false,
                    source_url: null,
                    author: null,
                    extract_id: null,
                    provider: null,
                    properties: null
                }
            }
        }]);
})();
