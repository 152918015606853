(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('UserInvitationDialogController', UserInvitationDialogController);

    /* @ngInject */
    function UserInvitationDialogController($scope, $stateParams, $mdDialog, $filter, UserInvitation, Principal, ToastService) {
        var vm = this;

        vm.parseToString = parseToString;
        vm.parseToArray = parseToArray;

        vm.invitationSent = false;
        vm.addManyAtOnce = false;

        vm.invitationModel = {
            email: null,
            first_name: null,
            last_name: null,
            type: 'ADMIN',
            invited_by: Principal.getUser()
        };

        vm.invitationsString = '';

        vm.invitations = [];
        vm.invitations.push(angular.copy(vm.invitationModel));
        vm.invitations.push(angular.copy(vm.invitationModel));
        vm.invitations.push(angular.copy(vm.invitationModel));

        vm.invitationsSuccess = [];
        vm.invitationsFailed = [];

        vm.clear = function() {
            $mdDialog.hide();
        };

        vm.seePending = function() {
            $mdDialog.hide();
        };

        vm.addAnother = function() {
            vm.invitations.push(angular.copy(vm.invitationModel));
        };

        vm.remove = function(index) {
            vm.invitations.splice(index, 1);
        };

        vm.sendInvitations = function() {
            var invitationsToSend = [];

            angular.forEach(vm.invitations, function(invitation) {
                if (invitation.email != null) {
                    invitationsToSend.push(invitation);
                }
            });

            if (invitationsToSend.length > 0) {
                UserInvitation.bulkInvitation(invitationsToSend, vm.onSaveSuccess, vm.onSaveError);
            } else {
                ToastService.displayToast($filter('triTranslate')('atheer.userManagement.addAtleatOneEmail'), 3000);
            }
        };

        vm.inviteMore = function() {
            vm.invitations = [];
            vm.invitations.push(angular.copy(vm.invitationModel));
            vm.invitations.push(angular.copy(vm.invitationModel));
            vm.invitations.push(angular.copy(vm.invitationModel));
            vm.invitationSent = false;
        }

        vm.onSaveSuccess = function(result) {
            vm.invitationsSuccess = [];
            vm.invitationsFailed = [];

            angular.forEach(result, function(invitation) {
                if (invitation.status == 'EXIST') {
                    vm.invitationsFailed.push(invitation);
                } else {
                    vm.invitationsSuccess.push(invitation);
                }
            });
            vm.invitationSent = true;
        };

        vm.onSaveError = function() {
            vm.isSaving = false;
        };

        function parseToString() {
            vm.invitationsString = ''

            angular.forEach(vm.invitations, function(invitation) {
                if (invitation.email && invitation.email != '') {
                    var nameStr = (invitation.first_name ? invitation.first_name + ' ' : '') + (invitation.last_name ? invitation.last_name + ' ' : '');
                    var emailStr = (invitation.email ? '<' + invitation.email + '>' : '');
                    vm.invitationsString += nameStr + emailStr + ';';
                }

            })
        }

        function parseToArray() {
            vm.invitations = []
            var userStringArray = vm.invitationsString.match(/[^,;]+/g);
            angular.forEach(userStringArray, function(userString) {
                if (isOnlyEmail(userString.trim())) {
                    var nameArray = userString.trim().match(/([a-zA-Z0-9]+)[._]?([a-zA-Z0-9]+)?/i)
                    vm.invitations.push({
                        email: userString.trim(),
                        first_name: nameArray[1],
                        last_name: nameArray[2]
                    });
                } else if (isInitialWithEmail(userString.trim())) {
                    var nameEmailArray = userString.match(/([a-zA-Z0-9]+)[ ]?([a-zA-Z0-9]+)?[ ]?<(.*)>/i)

                    if (isOnlyEmail(nameEmailArray[3].trim())) {
                        vm.invitations.push({
                            email: nameEmailArray[3],
                            first_name: nameEmailArray[1],
                            last_name: nameEmailArray[2]
                        });
                    }
                }
            });
        }

        function isOnlyEmail(emailStr) {
            return /^\w+([\.-\.+]?\w+)*@\w+([\.-\.+]?\w+)*(\.\w{2,3})+$/.test(emailStr);
        }

        function isInitialWithEmail(emailStr) {
            return /([a-zA-Z0-9]+)[ ]?([a-zA-Z0-9]+)?[ ]?<(.*)>/i.test(emailStr);
        }


    }
})();
