(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('ContentMenuController', ContentMenuController);

    /* @ngInject */
    function ContentMenuController($scope, $state, $mdDialog, $timeout, $mdPanel, Content, Topic, ParseLinks, AlertService, ContentAction, pagingParams, paginationConstants, $mdToast, $stateParams, $filter, CONTENT_META, $translate, Feature) {
        var vm = this;

        vm.loadingTime = 1500;
        vm.loadingData = true;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.predicate = pagingParams.predicate;
        vm.topics = null;
        vm.sequenceNumber = 0;
        vm.selectedNode = '';

        vm.createMenuOptions = $filter('filter')($filter('orderBy')(CONTENT_META, 'display_order'), {
            category: 'main',
            dispay_in_options: true
        });

        vm.filters = {
            metaType: 'all',
            featured: false,
            disabled: false,
            status: null,
            topicId: null
        };

        if ( getSavedFilters() ) {
            vm.filters = getSavedFilters();
        }

        vm.permissions = {
            manageTopic: 'manager-topic'
        };

        vm.updateFilters = function() {
            saveFilters(vm.filters);
            $scope.$emit('updateContentList', vm.filters);
        }

        vm.convertTypeForBackend = function(type) {
            return type.replace(/-/g, '_')
        }

        vm.loadAll = function() {
            vm.loadingData = true;
            Topic.query({
                page: pagingParams.page - 1,
                size: 1000,
                sort: sort(),
                query: getQueryString()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.topics = data.filter(function(topic) {
                    if (topic.parent_topics == null || topic.sub_topics != null) {
                        topic.id = vm.sequenceNumber++ +'::'+ topic.id;
                        if ( topic.id.includes(vm.filters.topicId) ) {
                            vm.selectedNode = topic;
                        }
                        return topic;
                    }
                });
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.loadingData = false;
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.loadingData = false;
            }
        };

        vm.treeOptions = {
            nodeChildren: "children",
            dirSelectable: true,
            injectClasses: {
                ul: "a1",
                li: "a2",
                liSelected: "a7",
                iExpanded: "a3",
                iCollapsed: "a4",
                iLeaf: "a5",
                label: "a6",
                labelSelected: "a8"
            }
        };

        vm.topicSelected = function(node, selected) {
            if (selected) {
                vm.filters.topicId = node.id.split('::')[1];
            } else {
                vm.filters.topicId = null;
            };
            saveFilters(vm.filters);
            $scope.$emit('updateContentList', vm.filters);
        };

        vm.fetchChildNodes = function(node, expanded) {
            
            function doFetch(node) {
                Topic.query({
                    page: pagingParams.page - 1,
                    size: 1000,
                    sort: sort(),
                    query: getChildQueryString(node)
                }, onSuccess, onError);

                function sort() {
                    var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                    if (vm.predicate !== 'id') {
                        result.push('id');
                    }
                    return result;
                }

                function onSuccess(data, headers) {
                    node.children = data;
                    node.children = data.filter(function(topic) {
                        topic.id = vm.sequenceNumber++ +'::'+ topic.id;
                        return topic;
                    });
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
            }
            if ( node.sub_topics != null && node.sub_topics.length > 0 ) {
                doFetch(node);
            }
        };

        function getQueryString() {
            return null;
        };

        function getChildQueryString(topic) {
            return 'id=in=(' + topic.sub_topics.toString() +')';
            
        };

        function saveFilters(filterData) {
            sessionStorage.setItem('objectMenu', JSON.stringify(filterData));
        };
    
        function getSavedFilters() {
            return JSON.parse(sessionStorage.getItem('objectMenu'));
        };

        vm.loadAll();
    }
})();
