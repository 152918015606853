(function() {
    'use strict';

    angular
        .module('atheer.conversation')
        .controller('ConversationListController', ConversationListController);

    /* @ngInject */
    function ConversationListController($rootScope, $scope, moment, $location, $state, Setting, PubNubService, AlertService, $mdDialog, ParseLinks, pagingParams, paginationConstants, $stateParams, Principal, Feature, PermissionService, User, Conversation, id, ConversationModelService, triSettings, $filter, NotificationHandleService, Experience, ToastService) {
        var vm = this;

        var showDownConverter = new showdown.Converter();
        showDownConverter.setOption('emoji', true);

        var conversationMessageInternalListener = null;
        vm.pinnedIndex = 0;

        vm.dateFormats = {
            sameDay: 'hh:mm A',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            lastDay: '[Yesterday]',
            lastWeek: 'dddd',
            sameElse: 'MMMM DD, YYYY'
        };

        vm.permissions = {
            create1on1: 'create-1-1-conversation',
            createGroup: 'create-group-conversation',
            update: 'update-conversation',
            delete: 'delete-conversation'
        };

        vm.loadingData = true;
        vm.maxDisplayLen = 15;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.conversations = [];
        vm.pinnedConversations = [];
        vm.userId = Principal.getUserId();
        vm.selectedConversationId = id;
        vm.groupConversationFeature = Feature.isActive('feature_conversation_group_conversation');
        vm.invitedUserIds = [];
        vm.hasPermission = PermissionService.hasAccess(vm.permissions.create1on1) || PermissionService.hasAccess(vm.permissions.createGroup);
        vm.primaryColor = triSettings.getSkinModel().primary_color_hex;

        vm.newConverationModel = {
            conversation_no: null,
            name: null,
            purpose: null,
            topic: null,
            status: 'OPEN',
            type: 'PRIVATE_USER',
            users: null

        };

        vm.messagesCount = 0;

        vm.startPeronsalConversation = function() {
            $mdDialog.show({
                    templateUrl: 'modules/user/user-picker.tmpl.html',
                    controller: 'UserPickerController',
                    controllerAs: 'vm',
                    locals: {
                        filter: vm.invitedUserIds,
                        allowMultiSelect: false,
                        userslist: [],
                        isSession: false,
                        isAdmin: false,
                        isScheduledSession: false,
                        isConversationSession: false,
                        isSmartScan: false
                    }
                })
                .then(function(selectedItems) {
                    createPersonalConversation(selectedItems);
                });
        };

        vm.startGroupConversation = function() {
            $mdDialog.show({
                templateUrl: 'modules/conversation/conversation-group-dialog.tmpl.html',
                clickOutsideToClose: true,
                controller: "ConversationGroupController",
                controllerAs: "vm",
                locals: {
                    filter: null
                }
            }).then(function(conversation) {
                createGroupConversation(conversation);
            }).finally(function() {});
        }

        vm.joinGroupConversation = function() {
            $mdDialog.show({
                    templateUrl: 'modules/conversation/conversation-group-picker.tmpl.html',
                    controller: 'ConversationGroupPickerController',
                    controllerAs: 'vm',
                    locals: {
                        filter: null,
                        allowMultiSelect: false
                    }
                })
                .then(function(conversations) {
                    joinCoversastion(conversations[0]);
                });
        }

        vm.getConverstionImageUrl = function(conversation) {
            if (conversation.type == 'PRIVATE_USER') {
                return conversation.user_info.picture_id ? '/media/pictures/' + conversation.user_info.picture_id : 'assets/images/avatars/user-avatar.png';
            } else if (conversation.type == 'PRIVATE_GROUP') {
                return conversation.picture_id ? '/media/pictures/' + conversation.picture_id : 'assets/images/avatars/group-avatar.png';
            } else if (conversation.type == 'PUBLIC_GROUP') {
                return conversation.picture_id ? '/media/pictures/' + conversation.picture_id : 'assets/images/avatars/group-avatar.png';
            } else if (conversation.type == 'PRIVATE_AUDIENCE') {
                return conversation.picture_id ? '/media/pictures/' + conversation.picture_id : 'assets/images/avatars/group-avatar.png';
            };
        };

        vm.getUserInitials = function(conversation) {
            var firstName = conversation.user_info.first_name;
            var lastName = conversation.user_info.last_name;
            return firstName.charAt(0) + lastName.charAt(0);
        };

        vm.getConverstionTitle = function(conversation) {
            var title = null;
            if (conversation.type == 'PRIVATE_USER') {
                title = conversation.user_info.first_name + ' ' + conversation.user_info.last_name;
            } else {
                title = conversation.name;
            }

            if (title != null) {
                var len = Math.min(vm.maxDisplayLen, title.length);
                var ans = title.substring(0, len);
                if (title.length > len) {
                    ans = ans + "...";
                }

                return ans;
            }

            return "";
        };

        vm.getConverstionLastMessage = function(conversation) {
            if (conversation && conversation.last_message) {
                if(conversation.last_message.type == 'TEXT') {
                    var lastmsg = conversation.last_message.body
                    if (lastmsg.length > 15) {
                        if(lastmsg.indexOf('<br>') != -1) {
                            var indexOf = lastmsg.indexOf('<br>') > 15 ? 15 : lastmsg.indexOf('<br>');
                            lastmsg = lastmsg.substring(0, indexOf) + '...'
                        } else {
                            lastmsg = lastmsg.substring(0,15) + "..."
                        }
                    }
                    return showDownConverter.makeHtml(lastmsg);
                } else if(conversation.last_message.type == 'AUDIO') {
                    return  'Audio Message';
                } else if(conversation.last_message.type == 'CONTENT') {
                    return  'Content Shared';
                } else {
                    if(conversation.last_message.type == 'URL') {
                        var last_message = conversation.last_message.body;
                        if(last_message.indexOf('<br>') != -1) {
                            last_message = last_message.substring(0, last_message.indexOf('<br>')) + '...';
                        }
                        return showDownConverter.makeHtml(last_message);
                    }
                    return showDownConverter.makeHtml(conversation.last_message.body);
                }
            }
        };

        vm.selectConversation = function(conversationId) {
            if (vm.selectedConversationId != conversationId) {
                vm.selectedConversationId = conversationId;

                $state.transitionTo('storm.conversations-detail', {
                    id: vm.selectedConversationId
                }, {
                    location: true,
                    inherit: true,
                    relative: $state.$current,
                    notify: false
                });

                var conversationToRefresh = null;
                if (conversationId != null) {
                    conversationToRefresh = vm.conversations.find(function(conversation) {
                        return conversation.id === conversationId;
                    });
                }

                // firing an event downwards
                $rootScope.$broadcast('refreshConversation', conversationToRefresh);

                getReadMessages();
            }
        };

        vm.isPinned = function(conversation) {
            if (conversation && conversation.users.length > 0) {
                for (var i = 0; i < conversation.users.length; i++) {
                    if (conversation.users[i].user_id == vm.userId && conversation.users[i].pinned) {
                        return true;
                    }
                };
            }

            return false;
        }

        vm.addUsersforPrivateGroup = function(conversation) {
            vm.invitedUserIds.push(conversation.user_info.id);
        }

        vm.removeUsersforPrivateGroupDelete = function(conversation) {
            var userIndex = vm.invitedUserIds.findIndex(function(id) {
                    return conversation.user_info.id === id;
                });

            vm.invitedUserIds.splice(userIndex, 1);
        }

        conversationMessageInternalListener = $rootScope.$on('conversationMessageInternal', function(event, messageInfo) {
            if (messageInfo.type == 'UPDATE_LAST_MESSAGE') {

                var conversationIndex = vm.conversations.findIndex(function(conversation) {
                    return conversation.id === messageInfo.message.conversation_id;
                });

                if (conversationIndex == 0) {
                    vm.conversations[0].last_message = messageInfo.message;

                } else {
                    var conversation = vm.conversations[conversationIndex];
                    conversation.last_message = messageInfo.message;
                    vm.conversations.splice(conversationIndex, 1);
                    if (vm.isPinned(conversation)) {
                        vm.conversations.unshift(conversation);
                    } else {
                        vm.conversations.splice(vm.pinnedIndex, 0, conversation);
                    }
                    vm.selectConversation(conversation.id);
                }
            } else if (messageInfo.type == 'CONVERSATION_DELETED' || messageInfo.type == 'CONVERSATION_ARCHIVED' || messageInfo.type == 'CONVERSATION_USER_LEFT') {
                var conversationIndex = vm.conversations.findIndex(function(conversation) {
                    return conversation.id === messageInfo.id;
                });

                if (vm.conversations.length <= 0) {
                    $state.go('storm.conversations');
                } else {
                    if (vm.isPinned(vm.conversations[conversationIndex])) {
                        vm.pinnedIndex--;
                    }

                    if (vm.conversations[conversationIndex].type == 'PRIVATE_USER') {
                        vm.removeUsersforPrivateGroupDelete(vm.conversations[conversationIndex]);
                    }

                    if (conversationIndex == 0) {
                        vm.conversations.shift();
                        if (vm.conversations.length > 0) {
                            vm.selectConversation(vm.conversations[0].id);
                        } else {
                            $state.go('storm.conversations');
                        }

                    } else if (conversationIndex > 0) {
                        vm.conversations.splice(conversationIndex, 1);
                        if (vm.selectedConversationId == messageInfo.id) {
                            if (vm.conversations.length > 0) {
                                vm.selectConversation(vm.conversations[0].id);
                            } else {
                                $state.go('storm.conversations');
                            }
                        }
                    }
                    var convChannelName = 'conversation-' + messageInfo.id;
                    PubNubService.unsubscribe([convChannelName]);
                }

            } else if (messageInfo.type == 'CONVERSATION_UPDATED') {
                var conversationIndex = vm.conversations.findIndex(function(conversation) {
                    return conversation.id === messageInfo.conversation.id;
                });

                vm.conversations[conversationIndex] = messageInfo.conversation;
            } else if (messageInfo.type == 'CONVERSATION_PINNED') {
                var conversationIndex = vm.conversations.findIndex(function(conversation) {
                    return conversation.id === messageInfo.id;
                });

                if (conversationIndex >= 0) {
                    var result = vm.conversations[conversationIndex];
                    for (var i = 0; i < result.users.length; i++) {
                        if (result.users[i].user_id == vm.userId) {
                            result.users[i].pinned = true;
                        }
                    };
                    vm.conversations.splice(conversationIndex, 1);
                    vm.conversations.splice(vm.pinnedIndex++, 0, result);
                    if (conversationIndex == 0) {
                        vm.selectConversation(result.id);
                    }
                }
            } else if (messageInfo.type == 'CONVERSATION_UNPINNED') {
                var conversationIndex = vm.conversations.findIndex(function(conversation) {
                    return conversation.id === messageInfo.id;
                });

                if (conversationIndex >= 0) {
                    var result = vm.conversations[conversationIndex];
                    for (var i = 0; i < result.users.length; i++) {
                        if (result.users[i].user_id == vm.userId) {
                            result.users[i].pinned = false;
                        }
                    };
                    vm.conversations.splice(conversationIndex, 1);
                    vm.pinnedIndex--;
                    vm.conversations.splice(vm.pinnedIndex, 0, result);
                    if (conversationIndex == 0) {
                        vm.selectConversation(result.id);
                    }
                }
            }
        });

        // Unregister
        $scope.$on('$destroy', function() {
            conversationMessageInternalListener = null;
        });

        $scope.$on('conversationMessagePubNub', function(event, message) {
            $scope.$apply(function() {
                if (message.message.subject == 'CONVERSATION') {
                    handleMessage(message);
                };
            });
        });

        function handleMessage(message) {
            if (message.message.type == 'REFRESH_CONVERSATION' || message.message.type == 'CONVERSATION_USER_LEFT') {
                var conversationId = message.message.data.id;

                var conversationIndex = vm.conversations.findIndex(function(conversation) {
                    return conversation.id == conversationId;
                });

                if (conversationIndex == -1) {
                    loadConversation(message.message.data.id);
                } else {
                    updateConversation(message.message.data.id, true);
                }
            } else if (message.message.type == 'CONVERSATION_DELETED' || message.message.type == 'CONVERSATION_ARCHIVED') {
                var conversationId = message.message.data.id;

                var conversationIndex = vm.conversations.findIndex(function(conversation) {
                    return conversation.id == conversationId;
                });

                if (vm.isPinned(vm.conversations[conversationIndex])) {
                    vm.pinnedIndex--;
                }

                if (vm.conversations[conversationIndex].type == 'PRIVATE_USER') {
                    vm.removeUsersforPrivateGroupDelete(vm.conversations[conversationIndex]);
                }

                if (conversationIndex == 0) {
                    vm.conversations.shift();
                    vm.selectConversation(vm.conversations[0].id);
                } else if (conversationIndex > 0) {
                    vm.conversations.splice(conversationIndex, 1);
                    if (vm.selectedConversationId == conversationId) {
                        vm.selectConversation(vm.conversations[0].id);
                    }
                }
            } else if (message.message.type == 'CONVERSATION_PINNED' || message.message.type == 'CONVERSATION_UNPINNED') {
                var conversationId = message.message.data.id;

                var conversationIndex = vm.conversations.findIndex(function(conversation) {
                    return conversation.id == conversationId;
                });

                updateConversation(message.message.data.id, false);

            } else if (message.message.type == 'MESSAGE_CREATED') {
                if(vm.selectedConversationId != message.message.data.conversation_id) {
                    NotificationHandleService.onNotification(message);
                    angular.forEach(vm.conversations, function(conversation) {
                        if(conversation.id == message.message.data.conversation_id) {
                            vm.messagesCount = vm.messagesCount + 1;
                            conversation.unread_message_count = conversation.unread_message_count + 1;

                            $rootScope.$broadcast('messageCount', vm.messagesCount);
                        }
                    });
                }
            }
        };

        function createPersonalConversation(users) {
            var conversation = angular.copy(vm.newConverationModel);
            var conversationUsers = [];

            conversationUsers.push({
                user_id: vm.userId,
                role: 'OWNER'
            });

            angular.forEach(users, function(user) {
                conversationUsers.push({
                    user_id: user.id,
                    role: 'OWNER'
                })
            });

            conversation.users = conversationUsers;
            createCoversastion(conversation);
        };

        function createGroupConversation(conversation) {
            createCoversastion(conversation);
        };

        function createCoversastion(conversation) {
            Conversation.save(conversation, onSaveSuccess, onSaveError);

            function onSaveSuccess(result) {
                vm.conversations.splice(vm.pinnedIndex, 0, result);
                vm.selectConversation(result.id);

                //subscribe to channel
                ConversationModelService.subscribe(result);

                //publish conversation to users
                ConversationModelService.publishNewConversation(vm.userId, result, 'REFRESH_CONVERSATION');

                ToastService.displayToast($filter('translate')('atheer.conversation.conversationCreated'), 3000);

                if (result.type == 'PRIVATE_USER') {
                    vm.addUsersforPrivateGroup(result);
                }
            };

            function onSaveError() {

            };
        };

        function joinCoversastion(conversation) {
            var conversationIndex = -1;
            conversationIndex = vm.conversations.findIndex(function(conversationExist) {
                    return conversationExist.id == conversation.id;
            });
             if(conversationIndex != -1) {
                if (vm.selectedConversationId == conversation.id) {
                    var conversationToRefresh = vm.conversations[conversationIndex];
                    vm.selectConversation(conversation.id);
                        // firing an event downwards
                    $rootScope.$broadcast('refreshConversation', conversationToRefresh);
                }
                vm.selectConversation(conversation.id);
            } else {
                Conversation.join({
                    id: conversation.id,
                }, onJoinSuccess, onJoinError);

                function onJoinSuccess(result) {
                    vm.conversations.splice(vm.pinnedIndex, 0, result);
                    vm.selectConversation(result.id);

                    //subscribe to channel
                    ConversationModelService.subscribe(result);

                    //publish conversation to users
                    ConversationModelService.publishNewConversation(vm.userId, result, 'REFRESH_CONVERSATION');

                    ToastService.displayToast($filter('translate')('atheer.conversation.conversationJoined'), 3000);
                };

                function onJoinError() {

                };
            }
        };

        function loadConversation(conversationId) {
            Conversation.get({
                id: conversationId,
            }, onGetSuccess, onGetError);

            function onGetSuccess(result) {
                if (vm.isPinned(result)) {
                    vm.conversations.unshift(result);
                } else {
                    vm.conversations.splice(vm.pinnedIndex, 0, result);
                }
                vm.selectConversation(result.id);

                //subscribe to channel
                ConversationModelService.subscribe(result);
                if (result.type == 'PRIVATE_USER') {
                    vm.addUsersforPrivateGroup(result);
                }

            };

            function onGetError() {

            };
        };

        function updateConversation(conversationId, select) {
            Conversation.get({
                id: conversationId,
            }, onGetSuccess, onGetError);

            function onGetSuccess(result) {
                var conversationIndex = vm.conversations.findIndex(function(conversation) {
                    return conversation.id == result.id;
                });
                vm.conversations[conversationIndex] = result;

                if (select) {
                    if (vm.selectedConversationId == result.id) {
                        var conversationToRefresh = vm.conversations[conversationIndex];

                        // firing an event downwards
                        $rootScope.$broadcast('refreshConversation', conversationToRefresh);
                    }
                }
            };

            function onGetError() {

            };
        }

        function filterConversations(conversations) {
            if(id != null) {
                vm.selectConversation(id);
            }
            vm.isReadingMessages = true;
            getUnReadMessageCount();
            angular.forEach(conversations, function(conversation) {

                var isPinned = false;
                for (var i = 0; i < conversation.users.length; i++) {
                    if (conversation.users[i].user_id == vm.userId && conversation.users[i].pinned) {
                        isPinned = true;
                        break;
                    }
                };

                if (isPinned) {
                    vm.conversations.splice(vm.pinnedIndex, 0, conversation);
                    vm.pinnedIndex++;
                } else {
                    if(conversation.type != 'PRIVATE_USER' && !vm.groupConversationFeature) {
                        return ;
                    }
                    vm.conversations.push(conversation);
                }

                if (conversation.type == 'PRIVATE_USER') {
                    vm.addUsersforPrivateGroup(conversation);
                }
            });
        }

        function loadData() {
            if(vm.selectedConversationId == null || vm.selectedConversationId.length == 0) {
                $state.go('storm.conversations');
            };

            vm.loadingData = true;

            if ($rootScope.isPersonalConv) {
                vm.startPeronsalConversation();
                $rootScope.isPersonalConv = false;
            } else if ($rootScope.isGroupConv) {
                vm.startGroupConversation();
                $rootScope.isGroupConv = false;
            } else if ($rootScope.isJoinConv) {
                vm.joinGroupConversation();
                $rootScope.isJoinConv = false;
            }

            User.getConversations({
                query: getOpenConversationQuery(),
                page: 0,
                size: 100
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                ConversationModelService.init(data);
                filterConversations(data);
                vm.loadingData = false;
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.loadingData = false;
            }
        };

        function getOpenConversationQuery() {
            return "status==OPEN"
        };

        function getUnReadMessageCount() {
            vm.messagesCount = 0;
            Experience.getUnReadMessageCount({
                id: vm.userId
            }, function(unreadMessagesList) {
                angular.forEach(unreadMessagesList, function(unreadMessage) {
                    angular.forEach(vm.conversations, function(conversation) {
                        if(unreadMessage && unreadMessage.object && conversation.id == unreadMessage.object.id) {
                            conversation.unread_message_count = unreadMessage.properties.count;
                            vm.messagesCount = vm.messagesCount + unreadMessage.properties.count;
                            $rootScope.$broadcast('messageCount', vm.messagesCount);
                        }
                    });
                });
                if(vm.isReadingMessages) {
                    vm.isReadingMessages = false;
                    getReadMessages();
                }
            });
        };

        function getReadMessages() {
            Experience.getReadMessages({
                userId: vm.userId,
                conversationId: vm.selectedConversationId
            }, function(readMessage) {
                angular.forEach(vm.conversations, function(conversation) {
                    if(conversation.id == readMessage.object.id) {
                        vm.messagesCount = vm.messagesCount - conversation.unread_message_count;

                        conversation.unread_message_count = readMessage.properties.count;
                        $rootScope.$broadcast('messageCount', vm.messagesCount);
                    }
                })
            });
        };
        loadData();
    }
})();
