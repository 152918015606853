(function() {
    'use strict';

    angular
        .module('atheer.session')
        .controller('SessionPlayerPasscodeController', SessionPlayerPasscodeController);

    /* @ngInject */
    function SessionPlayerPasscodeController($scope, $state, $q, $timeout, $filter, Report, sessionModel, $mdDialog, $mdToast, $stateParams) {
        var vm = this;

        vm.validPasscode = false;

        vm.passcode1 = null;
        vm.passcode2 = null;
        vm.passcode3 = null;
        vm.passcode4 = null;
        vm.passcode5 = null;
        vm.passcode6 = null;

        vm.sessionModel = sessionModel;

        vm.checkPasscode = function(modelVal, code) {
            if (modelVal != '' && modelVal != null && code < 6) {
                $timeout(function() {
                    var nextInput = '[ng-model="vm.passcode' + (code + 1) + '"]';
                    angular.element(nextInput).focus();
                });
            } else {
                var confirmationCode = vm.passcode1 + vm.passcode2 + vm.passcode3 + vm.passcode4 + vm.passcode5 + vm.passcode6;

                if (confirmationCode && confirmationCode.length == 6) {
                    if (confirmationCode == vm.sessionModel.passcode) {
                        vm.validPasscode = true;
                    } else {
                        vm.validPasscode = false;
                    }
                }
            };
        };

        vm.joinSession = function(launchPlayer) {
            $mdDialog.hide(launchPlayer);
        }
    }
})();
