(function() {
    'use strict';

    angular
        .module('atheer.core')
        .directive('fpTooltip', fpTooltip);

    /* @ngInject */
    function fpTooltip($compile, $mdUtil, $interpolate, $timeout) {
        return {
            restrict: 'E',
            scope: {
                options: '<',
                placement: '@'
            },
            link: function($scope, element, attrs) {

                var parent = element.parent();
                var defaultOptions = {
                    arrow: true,
                    delay: 100,
                    placement: 'bottom',
                    touch: false,
                    onShow: function (instance){
                        instance.setContent($interpolate(element.text())($scope));
                    }
                };

                if ($scope.placement) {
                    defaultOptions.placement = $scope.placement;
                } else {
                    defaultOptions.placement = 'bottom'
                };

                $scope.$watch('options', function(options) {
                    var tippyOptions = defaultOptions;

                    if (options) {
                        angular.merge(tippyOptions, defaultOptions, options);
                    }

                    var id = parent.attr('id');
                    if (id == undefined || id == null || id.length <= 0) {
                        id = 'id-' + $mdUtil.nextUid();
                        parent.attr("id", id);
                    };

                    $timeout(function() {
                        tippyOptions.content = $interpolate(element.text())($scope);
                        tippy('#' + id, tippyOptions);
                        element.remove();
                    }, 0);
                });
            }
        };
    }
})();
