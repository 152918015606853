(function() {
    'use strict';

    angular
        .module('atheer.user')
        .controller('NoAccessContoller', NoAccessContoller);

    /* @ngInject */
    function NoAccessContoller($rootScope, $mdDialog, $state, $q, $scope, $sce, triLoaderService, Principal, AlertService, $mdToast, Feature, Experience, $stateParams) {
        var vm = this;
        vm.buildInfoSettings = $rootScope.buildInfo;

        vm.isWorkspaceInactive = $stateParams.isWorkspaceInactive;
    }
})();
