(function () {
    'use strict';

    angular
        .module('atheer.services')
        .factory('Auth', Auth);

    /* @ngInject */
    function Auth($rootScope, $state, $q, $translate, Principal, AuthServerProvider, User, LoginService, PubNubService, $http, Setting, paginationConstants, toastConstants) {

        var service = {
            activateAccount: activateAccount,
            authorize: authorize,
            login: login,
            logout: logout,
            loginWithToken: loginWithToken,
            resetPasswordInit: resetPasswordInit,
            resetPasswordFinish: resetPasswordFinish,
            resetPasswordTemp: resetPasswordTemp,
            validateWorkspaceName: validateWorkspaceName,
            isPublicUser: isPublicUser
        };

        return service;

        function activateAccount(key, callback) {
            var cb = callback || angular.noop;

            return User.activate(key,
                function (response) {
                    return cb(response);
                },
                function (err) {
                    return cb(err);
                }.bind(this)).$promise;
        }

        function authorize(force) {
            var authReturn = Principal.identity(force).then(authThen);

            return authReturn;

            function authThen() {
                var isAuthenticated = Principal.isAuthenticated();

                // an authenticated user can't access to login and register pages
                if (isAuthenticated && $rootScope.toState.parent === 'account' && ($rootScope.toState.name === 'login' || $rootScope.toState.name === 'register' || $rootScope.toState.name === 'social-auth')) {
                    $state.go('home');
                }

                if ($rootScope.toState.data && $rootScope.toState.data.authorities && $rootScope.toState.data.authorities.length > 0 && !Principal.hasAnyAuthority($rootScope.toState.data.authorities)) {
                    if (isAuthenticated) {
                        // user is signed in but not authorized for desired state
                        $state.go('accessdenied');
                    } else {
                        // user is not authenticated. stow the state they wanted before you
                        // send them to the login service, so you can return them when you're done
                        $rootScope.redirected = true;
                        $rootScope.previousStateName = $rootScope.toState;
                        $rootScope.previousStateNameParams = $rootScope.toStateParams;

                        // now, send them to the signin state so they can log in
                        $state.go('accessdenied');
                        LoginService.open();
                    }
                }
            }
        }

        function login(credentials, callback) {
            var cb = callback || angular.noop;
            var deferred = $q.defer();

            AuthServerProvider.login(credentials)
                .then(loginThen)
                .catch(function (err) {
                    deferred.reject(err);
                    return cb(err);
                }.bind(this));

            function loginThen(data) {
                //to set pagination and toaster position we require this call
                Setting.getCompanySettings(function (data) {
                    angular.forEach(data[0].fields, function (field) {
                        if (field.name == 'default_page_size') {
                            paginationConstants.itemsPerPage = field.value;
                        } else if (field.name == 'default_toast_position') {
                            toastConstants.position = field.value.split("-").join(" ").toLowerCase();
                        }
                    });
                });
                Principal.identity(true)
                    .then(function (account) {
                        if (account != null) {
                            deferred.resolve(data);
                        } else {
                            deferred.reject();
                            return cb();
                        }
                    });
                return cb();
            }

            return deferred.promise;
        }


        function loginWithToken(jwt, rememberMe) {
            return AuthServerProvider.loginWithToken(jwt, rememberMe);
        }

        function logout() {
            AuthServerProvider.logout();
            Principal.logout(null);
            PubNubService.disconnect();
            // Reset state memory if not redirected
            if (!$rootScope.redirected) {
                $rootScope.previousStateName = undefined;
                $rootScope.previousStateNameParams = undefined;
            }
        }

        function resetPasswordInit(username, callback) {
            var cb = callback || angular.noop;

            var passwordInfo = {
                username: username
            };

            return User.passwordResetInit(passwordInfo, function () {
                return cb();
            }, function (err) {
                return cb(err);
            }).$promise;
        }

        function resetPasswordFinish(keyAndPassword, callback) {
            var cb = callback || angular.noop;
            return User.passwordResetFinish(keyAndPassword, function () {
                return cb();
            }, function (err) {
                return cb(err);
            }).$promise;
        }

        function resetPasswordTemp(username, callback) {
            var cb = callback || angular.noop;

            var passwordInfo = {
                username: username
            };

            return User.passwordResetTemp(passwordInfo, function () {
                return cb();
            }, function (err) {
                return cb(err);
            }).$promise;
        }

        function validateWorkspaceName(workspace) {
            var host = window.location.href.split('/')[2];
            var env = host.startsWith('localhost') ? 'dev' : host.split('.')[2];
            var hostname = host.startsWith('localhost') ? 'atheer' : host.split('.')[1];
            return $http.get('https://app.' + hostname + '.' + env + '/api/system/settings/workspace/info/' + workspace);
        }

        function isPublicUser() {
            return localStorage.getItem('Authorization') ? false : true;
        }
    }
})();
